.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.add,
.edit,
.register,
.login,
.events,
.vendors {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.add-form,
.edit-form,
.register-form,
.login-form,
.vendor-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid darkgray;
  border-radius: 15px;
  width: 15vw;
  padding: 5vh;
  margin: 5vh;
  box-shadow: 0px 0px 2px 0.2px #000000;
  list-style: none;
}

.add-form {
  width: 20vw;
}

.add-form button {
  border-radius: 1px;
  border: 1px solid darkgray;
  background-color: rgb(236, 236, 236);
}

.edit-form button,
.event-card button {
  margin: 0 5px;
}

.event-card {
  width: 20vw;
  list-style: none;
  border: 1px solid darkgray;
  border-radius: 15px;
  padding: 5vh;
  margin: 5vh 0;
  box-shadow: 0px 0px 2px 0.2px #000000;
}

.edit {
  height: 60vh;
}
